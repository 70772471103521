import { DualAxes } from '@ant-design/plots';
import { Link } from 'gatsby';
import React from 'react';
import CommonBanner from '../components/banner/CommonBanner';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import Seo from '../components/seo';

const DeepTierSCF = () => {
  return (
    <Layout>
      <Seo
        title="Deep Tier Supply Chain Finance"
        description="banco innovative solution unlocks value from deeper realm of the supply chain, expand your financing program and benefit more suppliers in your ecosystem."
      />
      <main className="main-wrapper">
        <HeaderCommon />
        {/* Deep-tier SCF */}
        <CommonBanner>
          <div className="deep-tier-wrap">
            <div className="content-wrap deep-mobile">
              <div className="content-item">
                <div className="-right">
                  <img className="light-mode line1" src="/images/deep-tier-img.png" />
                </div>
                <div className="-left">
                  <p className="title">Upsized Early Payment Programme</p>
                  <p className="-description">
                    Unlike other supply chain programmes currently in the market, banco's innovative
                    solution allows value to be transferred to the deeper realms of the supply chain
                    and as a result, expand your early financing programme to benefit more suppliers
                    in your ecosystem. More happy suppliers, stronger supply chain.
                  </p>
                  <div className="row">
                    <div className="col-8">
                      <Link className="banco-btn btn-fill-primary btn-fluid" to="/contact">
                        Speak with our team
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-wrap">
              <p className="title">Upsized Early Payment Programme</p>
              <div className="content-item">
                <div className="-left">
                  <p className="-description">
                    Unlike other supply chain programmes currently in the market, banco's innovative
                    solution allows value to be transferred to the deeper realms of the supply chain
                    and as a result, expand your early financing programme to benefit more suppliers
                    in your ecosystem. More happy suppliers, stronger supply chain.
                  </p>
                  <div className="row">
                    <div className="col-8">
                      <Link className="banco-btn btn-fill-primary btn-fluid" to="/contact">
                        Speak with our team
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="-right">
                  <img
                    className="light-mode line1"
                    src="/images/deep-tier-img.png"
                    style={{ width: 762 }}
                  />
                </div>
              </div>
            </div>
            <div className="content-2 deep-mobile">
              <p className="-title">
                Tip of your <br></br>“Supply Chain Ecosystem”
              </p>
              <img className="light-mode" src="/images/deep-tier-img-1.png" />
              <p className="-description">
                The World Economic Forum estimated that significant supply chain disruptions reduces
                share prices of companies by as much as 7%. A key reason is the lack of adequate
                visibility over your supply chain. It leads to loss of productivity, increased cost
                of working capital and damage to brand reputation. Most companies can only have
                visibility over their immediate Tier 1 suppliers and is limited beyond that. banco
                allows more transparency and visibility of your supply chain than most other SCR
                programmes out there.
              </p>
            </div>
            <div className="content-2 row">
              <div className="col-5">
                <img className="light-mode" src="/images/deep-tier-img-1.png" />
              </div>
              <div className="col">
                <p className="-title">Tip of your “Supply Chain Ecosystem”</p>
                <p className="-description">
                  The World Economic Forum estimated that significant supply chain disruptions
                  reduces share prices of companies by as much as 7%. A key reason is the lack of
                  adequate visibility over your supply chain. It leads to loss of productivity,
                  increased cost of working capital and damage to brand reputation. Most companies
                  can only have visibility over their immediate Tier 1 suppliers and is limited
                  beyond that. banco allows more transparency and visibility of your supply chain
                  than most other SCR programmes out there.
                </p>
              </div>
            </div>
            <div className="content-2 deep-mobile">
              <p className="-title">Risk increase further down the supply chain</p>
              <div className="-box">
                <DualAxes
                  padding={[24, 32]}
                  data={[
                    [
                      {
                        label: 'Tier 1',
                        value: 4.12,
                      },
                      {
                        label: 'Tier 2',
                        value: 4.85,
                      },
                      {
                        label: 'Tier 3',
                        value: 5.22,
                      },
                    ],
                    [
                      {
                        label: 'Tier 1',
                        percent: 41.2,
                      },
                      {
                        label: 'Tier 2',
                        percent: 48.5,
                      },
                      {
                        label: 'Tier 3',
                        percent: 5.2,
                      },
                    ],
                  ]}
                  legend={false}
                  xField="label"
                  yField={['value', 'percent']}
                  xAxis={{
                    label: {
                      style: {
                        fill: '#272727',
                        stroke: null,
                      },
                    },
                    line: {
                      style: {
                        stroke: '#D3DAE4',
                      },
                    },
                  }}
                  yAxis={{
                    value: {
                      min: 0,
                      max: 6,
                      label: {
                        style: {
                          fill: '#272727',
                          stroke: null,
                        },
                      },
                      title: {
                        text: 'Number of Non-Compliance (NCs)',
                      },
                      grid: null,
                      line: {
                        style: {
                          stroke: '#D3DAE4',
                        },
                      },
                    },
                    percent: {
                      min: 0,
                      max: 80,
                      label: {
                        formatter: (val) => `${val}%`,
                        style: {
                          fill: '#272727',
                          stroke: null,
                        },
                      },
                      title: {
                        text: 'Level of Engagement',
                      },
                      line: {
                        style: {
                          fill: '#D3DAE4',
                        },
                      },
                    },
                  }}
                  geometryOptions={[
                    {
                      geometry: 'column',
                      color: '#F9AA35',
                      columnWidthRatio: 0.4,
                      label: {
                        position: 'top',
                      },
                    },
                    {
                      geometry: 'line',
                      smooth: false,
                      color: '#D72D24',
                      lineStyle: {
                        lineWidth: 4,
                      },
                      point: {
                        size: 5,
                      },
                    },
                  ]}
                />
              </div>
              <p className="-description">
                The greatest and most critical sustainability risks are found deeper down the supply
                chain. Companies that reach past the first tiers improved supply chain management
                and met stated business and sustainability objectives. While risk increases down the
                supply chain, only a third of the companies are actively seeking transparency beyond
                Tier 1.
              </p>
            </div>
            <div className="content-2 row">
              <div className="col">
                <p className="-title">Risk increase further down the supply chain</p>
                <p className="-description">
                  The greatest and most critical sustainability risks are found deeper down the
                  supply chain. Companies that reach past the first tiers improved supply chain
                  management and met stated business and sustainability objectives. While risk
                  increases down the supply chain, only a third of the companies are actively
                  seeking transparency beyond Tier 1.
                </p>
              </div>
              <div className="col-6 -box">
                <DualAxes
                  padding={[24, 32]}
                  data={[
                    [
                      {
                        label: 'Tier 1',
                        value: 4.12,
                      },
                      {
                        label: 'Tier 2',
                        value: 4.85,
                      },
                      {
                        label: 'Tier 3',
                        value: 5.22,
                      },
                    ],
                    [
                      {
                        label: 'Tier 1',
                        percent: 41.2,
                      },
                      {
                        label: 'Tier 2',
                        percent: 48.5,
                      },
                      {
                        label: 'Tier 3',
                        percent: 5.2,
                      },
                    ],
                  ]}
                  legend={false}
                  xField="label"
                  yField={['value', 'percent']}
                  xAxis={{
                    label: {
                      style: {
                        fill: '#272727',
                        stroke: null,
                      },
                    },
                    line: {
                      style: {
                        stroke: '#D3DAE4',
                      },
                    },
                  }}
                  yAxis={{
                    value: {
                      min: 0,
                      max: 6,
                      label: {
                        style: {
                          fill: '#272727',
                          stroke: null,
                        },
                      },
                      title: {
                        text: 'Number of Non-Compliance (NCs)',
                      },
                      grid: null,
                      line: {
                        style: {
                          stroke: '#D3DAE4',
                        },
                      },
                    },
                    percent: {
                      min: 0,
                      max: 80,
                      label: {
                        formatter: (val) => `${val}%`,
                        style: {
                          fill: '#272727',
                          stroke: null,
                        },
                      },
                      title: {
                        text: 'Level of Engagement',
                      },
                      line: {
                        style: {
                          fill: '#D3DAE4',
                        },
                      },
                    },
                  }}
                  geometryOptions={[
                    {
                      geometry: 'column',
                      color: '#F9AA35',
                      columnWidthRatio: 0.4,
                      label: {
                        position: 'top',
                      },
                    },
                    {
                      geometry: 'line',
                      smooth: false,
                      color: '#D72D24',
                      lineStyle: {
                        lineWidth: 4,
                      },
                      point: {
                        size: 5,
                      },
                    },
                  ]}
                />
              </div>
            </div>
            <p className="-breadcrumb">banco vs SCF Programmes in the market</p>
            <table className="-table" rules="all">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <img className="light-mode" src="/images/logo-sticky.png" />
                  </th>
                  <th>Other Supply Chain programmes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Reach</td>
                  <td>Tier 1 – n</td>
                  <td>Tier 1</td>
                </tr>
                <tr>
                  <td>Visibility</td>
                  <td>Ecosystem</td>
                  <td>Limited to Tier 1</td>
                </tr>
                <tr>
                  <td>Resiliency & Competitiveness</td>
                  <td>Greatly Increased</td>
                  <td>Limited to Tier 1</td>
                </tr>
                <tr>
                  <td>Risk</td>
                  <td>Better Mitigated</td>
                  <td>Limited to Tier 1</td>
                </tr>
                <tr>
                  <td>ESG</td>
                  <td>Better Accessed</td>
                  <td>Limited to Tier 1</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CommonBanner>
        <Footer />
      </main>
    </Layout>
  );
};

export default DeepTierSCF;
